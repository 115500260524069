<template>
  <RecordViewWrapper>
    <sdPageHeader title="Realisasi KPI">
      <template #subTitle>
        <sdButton class="btn-add_new" size="default" type="primary">
          <router-link to="/master/realisasi-kpi-divisi-add"
            ><sdFeatherIcons type="plus" size="14" /> Tambah</router-link
          >
        </sdButton>
      </template>
      <template #buttons>
        <div class="search-box">
          <span class="search-icon">
            <sdFeatherIcons type="search" size="14" />
          </span>
          <a-input
            @change="handleSearch"
            v-model:value.trim="formState.searchItem"
            type="text"
            name="recored-search"
            placeholder="Search Here"
          /></div
      ></template>
    </sdPageHeader>

    <Main>
      <a-row :gutter="15">
        <a-col class="w-100" :md="24">
          <sdCards headless>
            <div v-if="isLoading" class="spin">
              <a-spin />
            </div>

            <div v-else>
              <TableWrapper class="table-data-view table-responsive">
                <a-table
                  :pagination="{ pageSize: 10, showSizeChanger: true }"
                  :dataSource="dataSource"
                  :columns="columns"
                />
              </TableWrapper>
            </div>
          </sdCards>
        </a-col>
      </a-row>
    </Main>
  </RecordViewWrapper>
</template>

<script>
import { RecordViewWrapper } from '../../../components/crud/style';
import { computed, onMounted, reactive } from 'vue';
import { Main, TableWrapper } from '../../styled';
import { useStore } from 'vuex';
import { Modal } from 'ant-design-vue';
import moment from 'moment';

const columns = [
  {
    title: 'KPI',
    dataIndex: 'kpi_divisi_id',
    key: 'kpi_divisi_id',
  },
  {
    title: 'Bulan',
    dataIndex: 'month',
    key: 'month',
  },
  {
    title: 'Realisasi Nominal',
    dataIndex: 'realisasi_nominal',
    key: 'realisasi_nominal',
    align: 'right',
  },
  {
    title: 'Realisasi Waktu',
    dataIndex: 'realisasi_waktu',
    key: 'realisasi_waktu',
  },
  {
    title: 'Pencapaian',
    dataIndex: 'pencapaian',
    key: 'pencapaian',
    align: 'right',
  },
  {
    title: 'Nilai',
    dataIndex: 'nilai',
    key: 'nilai',
    align: 'right',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];

const ViewPage = {
  name: 'ViewPage',
  components: { RecordViewWrapper, Main, TableWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const crud = computed(() => state.crud.data);
    const isLoading = computed(() => state.crud.loading);
    const months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    onMounted(() => {
      dispatch('axiosCrudGetData', 'realisasi-kpi-divisi');
    });

    const handleDelete = (id, variable_kpi_divisi, month) => {
      Modal.confirm({
        title: 'Konfirmasi',
        content: `Apakah Anda yakin ingin menghapus realisasi ${variable_kpi_divisi} ${months[month]}?`,
        okText: 'Hapus',
        cancelText: 'Batal',
        onOk: () => {
          dispatch('axiosDataDelete', {
            id,
            url: 'realisasi-kpi-divisi',
            getData: () => {
              dispatch('axiosCrudGetData', 'realisasi-kpi-divisi');
            },
          });
        },
      });
    };

    const formState = reactive({
      searchItem: '',
    });

    const dataSource = computed(() =>
      crud.value.length
        ? crud.value.map((data, key) => {
            const { id, kpi_divisi, month, realisasi_nominal, realisasi_waktu, pencapaian, nilai } = data;

            return {
              key: key + 1,
              kpi_divisi_id: kpi_divisi ? kpi_divisi['name'] : '',
              month: months[month],
              realisasi_nominal,
              realisasi_waktu: realisasi_waktu ? moment(realisasi_waktu).format('MMM YYYY') : '',
              pencapaian,
              nilai,
              action: (
                <div class="table-actions">
                  <router-link class="edit" to={`/master/realisasi-kpi-divisi-edit/${id}`}>
                    <sdFeatherIcons type="edit" size={14} title="Ubah" />
                  </router-link>
                  &nbsp;&nbsp;&nbsp;
                  <router-link
                    class="delete"
                    onClick={() => handleDelete(id, data.kpi_divisi['name'], data.month)}
                    to="#"
                  >
                    <sdFeatherIcons type="trash-2" size={14} title="Hapus" />
                  </router-link>
                </div>
              ),
            };
          })
        : [],
    );

    const handleSearch = () => {
      dispatch('axiosDataSearch', { url: 'realisasi-kpi-divisi', filter: formState.searchItem });
    };

    return {
      formState,
      columns,
      isLoading,
      crud,
      dataSource,
      handleDelete,
      handleSearch,
    };
  },
};

export default ViewPage;
</script>
